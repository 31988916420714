import { log } from "node:console";

export function bkcolor(details: any, category: any) {
  const height = parseInt(details.boxheightcat) - 50;
  const width = parseInt(details.boxwidthcat) - 50;
  const quantity = 1;
  const Label = parseInt(details.boxdepthcat) - 50;
  
  const heightquantity = height * quantity;
  const widthquantity = width * quantity;

  return {
    ...details,
    updatedcategory: category,
    formulaupdatedboxheightcat: height,
    formulaupdatedboxwidthcat: width,
    quantity,
    heightquantity,
    widthquantity,
    Label,
  };
}
export function single_lip(details: any, category: any) {
  const height = parseInt(details.boxheightcat);
  const width = parseInt(details.boxwidthcat);
  const quantity = 1;
  const heightquantity = height * quantity;
  const widthquantity = width * quantity;
  return {
    ...details,
    formulaupdatedboxheightcat: details.boxheightcat,
    formulaupdatedboxwidthcat: details.boxwidthcat,
    quantity,
    heightquantity,
    widthquantity,
  };
}
export function depth(details: any, category: any) {
  const depth = parseInt(details.boxdepthcat) - 50;
  const quantity = 4;
  const depthquantity = depth * quantity;
  return {
    ...details,
    formulaupdatedboxdepthcat: depth,
    depthquantity,
    quantity,
  };
}
export function shelf(details: any, category: any) {
  const depth = parseInt(details.boxdepthcat) - 60;
  const width = parseInt(details.boxwidthcat) - 51;
  const quantity = details.boxshelf_countcat;
  const heightquantity = depth * quantity;
  const widthquantity = width * quantity;

  return {
    ...details,
    formulaupdatedboxdepthcat: depth,
    formulaupdatedboxwidthcat: width,
    heightquantity,
    widthquantity,
    quantity,
  };
}
export function onecrossone(details: any, category: any) {
  const height = parseInt(details.height);
  const width = parseInt(details.width);
  const quantity = 1;
  const heightquantity = height * quantity;
  const widthquantity = width * quantity;

  return {
    ...details,
    updatedframeheightcat: height,
    updatedframewidthcat: width,
    quantity,
    heightquantity,
    widthquantity,
  };
}
export function framehightwidth(details: any, category: any) {
  const totalheightwidth =
    parseInt(details.totalframeHeight) + parseInt(details.totalframewidth);
  const a = totalheightwidth / 305;
  const b = a / 12;
  const nos = Math.floor(b * 2) + 1;

  return {
    ...details,
    updatetotalnos: nos,
  };
}
export function totaldepth(details: any, category: any) {
  const a = parseInt(details.totalBoxDepth) / 3600;
  const nos = Math.round(a);
  return {
    ...details,
    totalnos: nos,
  };
}
export function totalhightwidth(details: any, category: any) {
  const totalheightwidth =
    parseInt(details.totalBoxHeight) + parseInt(details.totalBoxwidth);
  const a = totalheightwidth / 305;
  const b = a / 12;
  const nos = Math.floor(b * 2) + 1;

  return {
    ...details,
    updatetotalnos: nos,
  };
}
export function jsquarehightwidth(details: any, category: any) {
  const totalheightwidth =
    parseInt(details.totaljsquareHeight) + parseInt(details.totaljsquarewidth);
  const a = totalheightwidth / 305;
  const b = a / 12;
  const nos = Math.floor(b * 2) + 1;
  return {
    ...details,
    jsquaretotalnos: nos,
  };
}

export function GlassListhightwidth(details: any, category: any) {
  const totalheightwidth =  parseInt(details.totalGlasslistHeight) + parseInt(details.totalGlasslistwidth);
  const a = totalheightwidth / 305;
  const b = a / 12;
  const nos = Math.floor(b * 2) + 1;
  return {
    ...details,
    Glasslisttotalnos: nos,
  };
}

export function doorsheethightwidth(details: any, category: any) {
  const totalheightwidth =
    parseInt(details.totaldoorsheetHeight) + parseInt(details.totaldoorsheetwidth);
  const a = totalheightwidth / 305;
  const b = a / 12;
  const nos = Math.floor(b * 2) + 1;
  return {
    ...details,
    jsquaretotalnos: nos,
  };
}

export function FaciaDrawerhightwidth(details: any, category: any) {
  const totalheightwidth =
    parseInt(details.totalFaciaDrawerHeight) + parseInt(details.totalFaciaDrawerwidth);
  const a = totalheightwidth / 305;
  const b = a / 12;
  const nos = Math.floor(b * 2) + 1;

  return {
    ...details, 
    Facia100mmDrawertotalnos: nos,
  };
}
export function FaciatandemDrawerhightwidth(details: any, category: any) {
  const totalheightwidth =
    parseInt(details.totaljsquareHeight) + parseInt(details.totaljsquarewidth);
  const a = totalheightwidth / 305;
  const b = a / 12;
  const nos = Math.floor(b * 2) + 1;

  return {
    ...details,
    FaciaDrawertotalnos: nos,
  };
}
export function IvorySheethightwidth(details: any, category: any) {
  const totalheightwidth =
    parseInt(details.totaljsquareHeight) + parseInt(details.totaljsquarewidth);
  const a = totalheightwidth / 305;
  const b = a / 12;
  const nos = Math.floor(b * 2) + 1;

  return {
    ...details,
    FaciaDrawertotalnos: nos,
  };
}

export function FaciaInternalDrawerhightwidth(details: any, category: any) {
  const totalheightwidth =
    parseInt(details.totalFaciaInternalDrawerHeight) + parseInt(details.totalFaciaInternalDrawerWidth);
  const a = totalheightwidth / 305;
  const b = a / 12;
  const nos = Math.floor(b * 2) + 1;
  return {
    ...details,
    FaciaInDrawertotalnos: nos,
  };
  
}

export function formulajsquare(details: any, category: any) {

  console.log("formulajsquare--->data--->Details-->",details);
  
  let height = details.boxheightcat ? parseInt(details.boxheightcat) : parseInt(details.frameheightcat);
  let width = details.boxwidthcat ? parseInt(details.boxwidthcat) : parseInt(details.framewidthcat);
  let quantity = details.frametypecat === "Ep" ? 1
      : details.boxdoor_selectcat === "3 drawer" ? 3
      : details.boxdoor_selectcat === "2 drawer" ? 2
      : details.boxdoor_selectcat === "3 drawer 2:1" ? 1
      : details.boxdoor_selectcat === "2 drawer 2:1" ? 1
      : details.boxdoor_selectcat === "Single Drawer" ? 2
      : details.boxdoor_selectcat === "Double Drawer" ? 2
      : details.boxdoor_selectcat === "Double" || details.framedoortypeselectcat === "Double" ? 2 : 1;
  let boxint_dummycat = details.boxint_dummycat;
  if (!boxint_dummycat) {
    boxint_dummycat = "0";
  }
  let frameintdummycat = details.frameintdummycat;
  if (!frameintdummycat) {
    frameintdummycat = "0";
  }
  let boxint_boxgcat = details.boxgcat;
  if (!boxint_boxgcat) {
    boxint_boxgcat = "0";
  }
  let boxint_framegcat = details.framegcat;
  if (!boxint_framegcat) {
    boxint_framegcat = "0";
  }

  if (details.boxdepthcat) {
    if (
      details.boxdoor_typecat === "Sheet Door" &&
      details.boxdoor_selectcat === "Single"
    ) {
      height = parseInt(details.boxheightcat) - 20 - parseInt(boxint_boxgcat);
      width = parseInt(details.boxwidthcat) - 20 - parseInt(boxint_dummycat);
    } else if (
      details.boxdoor_typecat == "Sheet Door" &&
      details.boxdoor_selectcat == "Double"
    ) {
      height = parseInt(details.boxheightcat) - 20 - parseInt(boxint_boxgcat);
      const widthCalculation =
        parseInt(details.boxwidthcat) - 22 - parseInt(boxint_dummycat);
      width = widthCalculation / 2;
    }
     else if (
      details.boxdoor_typecat == "Glass Door" &&
      details.boxdoor_selectcat == "Single"
    ) {
      height = parseInt(details.boxheightcat) - 22 - parseInt(boxint_boxgcat);
      width = parseInt(details.boxwidthcat) - 22 - parseInt(boxint_dummycat);
    } else if (
      details.boxdoor_typecat == "Glass Door" &&
      details.boxdoor_selectcat == "Double"
    ) {
      height = parseInt(details.boxheightcat) - 20 - parseInt(boxint_boxgcat);
      const widthCalculation =
      parseInt(details.boxwidthcat) - 22 - parseInt(boxint_dummycat);
      const widthsecstep = widthCalculation / 2  ;
      width = widthsecstep ;
    }
   
     else if (
      details.boxdoor_typecat == "Facia" &&
      details.boxdoor_selectcat == "2 drawer"
    ) {
      const heightCalculation =
        parseInt(details.boxheightcat) -
        24 -
        parseInt(boxint_boxgcat) -
        parseInt(boxint_boxgcat);
      height = heightCalculation / 2;
      width = parseInt(details.boxwidthcat) - 20 - parseInt(boxint_dummycat);
    } else if (
      details.boxdoor_typecat == "Facia" &&
      details.boxdoor_selectcat == "3 drawer"
    ) {
      const heightCalculation =
        parseInt(details.boxheightcat) -
        28 -
        parseInt(boxint_boxgcat) -
        parseInt(boxint_boxgcat);
      height = heightCalculation / 3;
      width = parseInt(details.boxwidthcat) - 20 - parseInt(boxint_dummycat);
    }
    //100mm  drawer 2 3 2:1//
    else if (
      details.boxdoor_typecat == "Facia" &&
      details.FaciaDrawer == "1" &&
      details.boxdoor_selectcat == "2 drawer 2:1" &&
      details.Twodrawer =="1" 
    ) {
      const heightCalculation =
        parseInt(details.boxheightcat) - 24 - parseInt(boxint_boxgcat) - parseInt(boxint_boxgcat);
      height = heightCalculation / 3;
      width = parseInt(details.boxwidthcat) - 20 - parseInt(boxint_dummycat);
    }  else if (
      details.boxdoor_typecat == "Facia" &&
      details.FaciaDrawer == "1" &&
      details.boxdoor_selectcat == "2 drawer 2:1" &&
      details.Twodrawer =="2" 
    ) {
      const heightCalculation = parseInt(details.boxheightcat) - 24 - parseInt(boxint_boxgcat) - parseInt(boxint_boxgcat);
     const  intoheight = heightCalculation / 3;
     height = intoheight * 2 ;
      width = parseInt(details.boxwidthcat) - 20 - parseInt(boxint_dummycat);
    } 
    else if (
      details.boxdoor_typecat == "Facia" &&
      details.FaciaDrawer == "1" &&
      details.boxdoor_selectcat == "3 drawer 2:1" &&
      details.Twodrawer =="1" 
    ) {
      const heightCalculation = parseInt(details.boxheightcat) - 28 -
      parseInt(boxint_boxgcat) -
      parseInt(boxint_boxgcat);
    const intoheight = heightCalculation / 2;
    height = intoheight /2 ;
    width = parseInt(details.boxwidthcat) - 20 - parseInt(boxint_dummycat);
    }
    else if (
      details.boxdoor_typecat == "Facia" &&
      details.FaciaDrawer == "1" &&
      details.boxdoor_selectcat == "3 drawer 2:1" &&
      details.Twodrawer =="2" 
    ) {
      const heightCalculation = parseInt(details.boxheightcat) - 28 -
      parseInt(boxint_boxgcat) -
      parseInt(boxint_boxgcat);
    const intoheight = heightCalculation / 2;
    height = intoheight /2 ;
    width = parseInt(details.boxwidthcat) - 20 - parseInt(boxint_dummycat);
    }
    else if (
      details.boxdoor_typecat == "Facia" &&
      details.FaciaDrawer == "1" &&
      details.boxdoor_selectcat == "3 drawer 2:1" &&
      details.Twodrawer =="3" 
    ) {
      const heightCalculation = parseInt(details.boxheightcat) - 28 -
      parseInt(boxint_boxgcat) -
      parseInt(boxint_boxgcat);
    height = heightCalculation /2 ;
    width = parseInt(details.boxwidthcat) - 20 - parseInt(boxint_dummycat);
    }
    //tendem trawer 2 3 2:1//
    else if (
      details.boxdoor_typecat == "Facia" &&
      details.FaciaDrawer == "2" &&
      details.boxdoor_selectcat == "2 drawer 2:1" &&
      details.Twodrawer =="1" 
    ) {
      const heightCalculation =
        parseInt(details.boxheightcat) - 24 - parseInt(boxint_boxgcat) - parseInt(boxint_boxgcat);
      height = heightCalculation / 3;
      width = parseInt(details.boxwidthcat) - 20 - parseInt(boxint_dummycat);
    }  else if (
      details.boxdoor_typecat == "Facia" &&
      details.FaciaDrawer == "2" &&
      details.boxdoor_selectcat == "2 drawer 2:1" &&
      details.Twodrawer =="2" 
    ) {
      const heightCalculation = parseInt(details.boxheightcat) - 24 - parseInt(boxint_boxgcat) - parseInt(boxint_boxgcat);
     const  intoheight = heightCalculation / 3;
     height = intoheight * 2 ;
      width = parseInt(details.boxwidthcat) - 20 - parseInt(boxint_dummycat);
    } 
    else if (
      details.boxdoor_typecat == "Facia" &&
      details.FaciaDrawer == "2" &&
      details.boxdoor_selectcat == "3 drawer 2:1" &&
      details.Twodrawer =="1" 
    ) {
      const heightCalculation = parseInt(details.boxheightcat) - 28 -
      parseInt(boxint_boxgcat) -
      parseInt(boxint_boxgcat);
    const intoheight = heightCalculation / 2;
    height = intoheight /2 ;
    width = parseInt(details.boxwidthcat) - 20 - parseInt(boxint_dummycat);
    }
    else if (
      details.boxdoor_typecat == "Facia" &&
      details.FaciaDrawer == "2" &&
      details.boxdoor_selectcat == "3 drawer 2:1" &&
      details.Twodrawer =="2" 
    ) {
      const heightCalculation = parseInt(details.boxheightcat) - 28 -
      parseInt(boxint_boxgcat) -
      parseInt(boxint_boxgcat);
    const intoheight = heightCalculation / 2;
    height = intoheight /2 ;
    width = parseInt(details.boxwidthcat) - 20 - parseInt(boxint_dummycat);
    }
    else if (
      details.boxdoor_typecat == "Facia" &&
      details.FaciaDrawer == "2" &&
      details.boxdoor_selectcat == "3 drawer 2:1" &&
      details.Twodrawer =="3" 
    ) {
      const heightCalculation = parseInt(details.boxheightcat) - 28 -
      parseInt(boxint_boxgcat) -
      parseInt(boxint_boxgcat);
    height = heightCalculation /2 ;
    width = parseInt(details.boxwidthcat) - 20 - parseInt(boxint_dummycat);
    }
    else if (
      details.boxdoor_typecat == "Facia" &&
      details.FaciaDrawer == "3" &&
      details.boxdoor_selectcat == "Single Drawer" 
    ) {
      height = parseInt(details.boxheightcat) - 20 - parseInt(boxint_boxgcat);
      width = parseInt(details.boxwidthcat) - 20 - parseInt(boxint_dummycat);
    }
    else if (
      details.boxdoor_typecat == "Facia" &&
      details.FaciaDrawer == "3" &&
      details.boxdoor_selectcat == "Double Drawer" 
    ) {
      height = parseInt(details.boxheightcat) - 20 - parseInt(boxint_boxgcat);
      const widthCalculation =
        parseInt(details.boxwidthcat) - 30 - parseInt(boxint_dummycat);
      width = widthCalculation / 2;
    }
    else if (
      details.boxdoor_typecat == "Facia" &&
      details.FaciaDrawer == "0" && details.boxg_valuecat =="1"
    ) {
      height = parseInt(details.boxheightcat) - 20 - parseInt(details.boxgcat) ;
      width = parseInt(details.boxwidthcat) - 20  ;
    }
    else if (
      details.boxdoor_typecat == "Facia" &&
      details.FaciaDrawer == "0" 
    ) {
      height = parseInt(details.boxheightcat) - 20 ;
      width = parseInt(details.boxwidthcat) - 20 ;
    }
  }
   else {
    if (
      (details.frametypecat == "1 x 1" || details.frametypecat == "1 x 1 Pa Frame") &&
      details.framedoortypecat == "Sheet Door" &&
      details.framedoortypeselectcat ===   "Single"
    ) {
      height =
        parseInt(details.frameheightcat) - 20 - parseInt(boxint_framegcat);
      width = parseInt(details.framewidthcat) - 20 - parseInt(frameintdummycat);
    } else if (
      (details.frametypecat == "1 x 1" || details.frametypecat == "1 x 1 Pa Frame") &&
      details.framedoortypecat == "Sheet Door" &&
      details.framedoortypeselectcat === "Double"
    ) {
      height =
        parseInt(details.frameheightcat) - 20 - parseInt(boxint_framegcat);
      const widthCalculation =
        parseInt(details.framewidthcat) - 22 - parseInt(frameintdummycat);
      width = widthCalculation / 2;
    } else if (
      (details.frametypecat == "1 x 1" || details.frametypecat == "1 x 1 Pa Frame") &&
      details.framedoortypecat == "Glass Door" &&
      details.framedoortypeselectcat == "Single"
    ) {
      height =
        parseInt(details.frameheightcat) - 22 - parseInt(boxint_framegcat);
      width = parseInt(details.framewidthcat) - 22 - parseInt(frameintdummycat);
    } else if (
      (details.frametypecat == "1 x 1" || details.frametypecat == "1 x 1 Pa Frame") &&
      details.framedoortypecat == "Glass Door" &&
      details.framedoortypeselectcat == "Double"
    ) {
      height =
        parseInt(details.frameheightcat) - 20 - parseInt(boxint_framegcat);
      const widthCalculation =
        parseInt(details.framewidthcat) - 22 - parseInt(frameintdummycat);
      width = widthCalculation / 2;
    } else if (
      (details.frametypecat === "1.25" ||
        details.frametypecat === "1.25 Pa Frame" ||
        details.frametypecat === "1.25 single lip") &&
      details.framedoortypecat === "Sheet Door" &&
      details.framedoortypeselectcat === "Single"
    ) {
      height =
        parseInt(details.frameheightcat) - 20 - parseInt(boxint_framegcat);
      width = parseInt(details.framewidthcat) - 20 - parseInt(frameintdummycat);
    } else if (
      (details.frametypecat == "1.25" ||
        details.frametypecat === "1.25 Pa Frame" ||
        details.frametypecat == "1.25 single lip") &&
      details.framedoortypecat == "Sheet Door" &&
      details.framedoortypeselectcat == "Double"
    ) {
      height =
        parseInt(details.frameheightcat) - 20 - parseInt(boxint_framegcat);
      const widthCalculation =
        parseInt(details.framewidthcat) - 32 - parseInt(frameintdummycat);
      width = widthCalculation / 2;
    } else if (
      (details.frametypecat == "1.25" ||
        details.frametypecat === "1.25 Pa Frame" ||
        details.frametypecat == "1.25 single lip") &&
      details.framedoortypecat == "Glass Door" &&
      details.framedoortypeselectcat == "Single"
    ) {
      height =
        parseInt(details.frameheightcat) - 20 - parseInt(boxint_framegcat);
      width = parseInt(details.framewidthcat) - 32 - parseInt(frameintdummycat);
    } else if (
      (details.frametypecat == "1.25" ||
        details.frametypecat === "1.25 Pa Frame" ||
        details.frametypecat == "1.25 single lip") &&
      details.framedoortypecat == "Glass Door" &&
      details.framedoortypeselectcat == "Double"
    ) {
      console.log("murugaa",details.frameheightcat,details.framewidthcat)
      height =
        parseInt(details.frameheightcat) - 20 - parseInt(boxint_framegcat);
        console.log("om muruga--->",height)
      const widthCalculation =
        parseInt(details.framewidthcat) - 32 - parseInt(frameintdummycat);
      width = widthCalculation / 2;
      console.log("om muruga--->",width)
    } else if (details.frametypecat == "Ep") {
      height = parseInt(details.frameheightcat);
      width = parseInt(details.framewidthcat);
    }
  }
  const heightquantity = height * quantity;
  const widthquantity = width * quantity;

  const updateheight = Math.floor(height);

  const updatedWidth =
    boxint_boxgcat > 0 || boxint_framegcat > 0
      ? Math.floor(width) + "G"
      : Math.floor(width);

  console.log("omm return-->",updateheight,updatedWidth);

  return {
    ...details,
    formulaupdatedboxheightcat: updateheight,
    formulaupdatedboxwidthcat: updatedWidth,
    quantity,
    heightquantity,
    widthquantity,
  };
  
}
export function formulaglasslist(details: any, category: any) {
  let height = details.boxheightcat
    ? parseInt(details.boxheightcat)
    : parseInt(details.frameheightcat);
  let width = details.boxwidthcat
    ? parseInt(details.boxwidthcat)
    : parseInt(details.framewidthcat);

  let quantity =
    details.frametypecat === "Ep" ? 1
      : details.boxdoor_selectcat === "3 drawer" ? 3
      : details.boxdoor_selectcat === "2 drawer"? 2: details.boxdoor_selectcat === "Double" ||details.framedoortypeselectcat === "Double"
      ? 2: 1;
  let boxint_dummycat = details.boxint_dummycat;
  if (!boxint_dummycat) {boxint_dummycat = "0";}
  let frameintdummycat = details.frameintdummycat;
  if (!frameintdummycat) {frameintdummycat = "0";}
  let boxint_boxgcat = details.boxgcat;
  if (!boxint_boxgcat) {boxint_boxgcat = "0";}
  let boxint_framegcat = details.framegcat;
  if (!boxint_framegcat) {boxint_framegcat = "0";}
  if (details.boxdepthcat) {
    if (details.boxdoor_typecat === "Sheet Door" &&
      details.boxdoor_selectcat === "Single"
    ) {
      height = parseInt(details.boxheightcat) - 20 - parseInt(boxint_boxgcat);
      width = parseInt(details.boxwidthcat) - 20 - parseInt(boxint_dummycat);
    } else if (
      details.boxdoor_typecat == "Sheet Door" &&
      details.boxdoor_selectcat == "Double"
    ) {
      height = parseInt(details.boxheightcat) - 20 - parseInt(boxint_boxgcat);
      const widthCalculation =parseInt(details.boxwidthcat) - 30 - parseInt(boxint_dummycat);
      width = widthCalculation / 2;
    }
     else if (
      details.boxdoor_typecat == "Glass Door" &&
      details.boxdoor_selectcat == "Single"
    ) {
      height = parseInt(details.boxheightcat) - 22 - parseInt(boxint_boxgcat);
      width = parseInt(details.boxwidthcat) - 22 - parseInt(boxint_dummycat);
      const sftvalueget = parseInt(details.formulaupdatedboxheightcat) * parseInt(details.formulaupdatedboxwidthcat);
      const sftqty = sftvalueget /90000;
       const SFT = sftqty * quantity ;
       const roundedSFT = SFT.toFixed(1);
       details.SFTValue = roundedSFT;
       
    } else if (
      details.boxdoor_typecat == "Glass Door" &&
      details.boxdoor_selectcat == "Double"
    ) {
      height = parseInt(details.boxheightcat) - 22 - parseInt(boxint_boxgcat);
      const widthCalculation =
        parseInt(details.boxwidthcat) - 30 - parseInt(boxint_dummycat);
        const widthsecstep = widthCalculation / 2;
        width = widthsecstep - 3;
        const sftvalueget = parseInt(details.formulaupdatedboxheightcat) * parseInt(details.formulaupdatedboxwidthcat);
        const sftqty = sftvalueget /90000;
         const SFT = sftqty * quantity ;
         const roundedSFT = SFT.toFixed(1);
         details.SFTValue = roundedSFT;
    }
     else if (
      details.boxdoor_typecat == "Facia" &&
      details.boxdoor_selectcat == "2 drawer"
    ) {
      const heightCalculation =
        parseInt(details.boxheightcat) -
        24 -
        parseInt(boxint_boxgcat) -
        parseInt(boxint_boxgcat);
      height = heightCalculation / 2;
      width = parseInt(details.boxwidthcat) - 20 - parseInt(boxint_dummycat);
    } else if (
      details.boxdoor_typecat == "Facia" &&
      details.boxdoor_selectcat == "3 drawer"
    ) {
      const heightCalculation =
        parseInt(details.boxheightcat) -
        28 -
        parseInt(boxint_boxgcat) -
        parseInt(boxint_boxgcat);
      height = heightCalculation / 2;
      width = parseInt(details.boxwidthcat) - 20 - parseInt(boxint_dummycat);
    }
  } else {
    if (
      (details.frametypecat == "1 x 1" || details.frametypecat == "1 x 1 Pa Frame") &&
      details.framedoortypecat == "Sheet Door" &&
      details.framedoortypeselectcat == "Single"
    ) {
      height =
        parseInt(details.frameheightcat) - 20 - parseInt(boxint_framegcat);
      width = parseInt(details.framewidthcat) - 20 - parseInt(frameintdummycat);
    } else if (
      (details.frametypecat == "1 x 1" || details.frametypecat == "1 x 1 Pa Frame") &&
      details.framedoortypecat == "Sheet Door" &&
      details.framedoortypeselectcat == "Double"
    ) {
      height =
        parseInt(details.frameheightcat) - 20 - parseInt(boxint_framegcat);
      const widthCalculation =
        parseInt(details.framewidthcat) - 24 - parseInt(frameintdummycat);
      width = widthCalculation / 2;
    } else if (
      (details.frametypecat == "1 x 1" || details.frametypecat == "1 x 1 Pa Frame") &&
      details.framedoortypecat == "Glass Door" &&
      details.framedoortypeselectcat == "Single"
    ) {
      height =parseInt(details.frameheightcat) - 22 - parseInt(boxint_framegcat);
      width = parseInt(details.framewidthcat) - 22 - parseInt(frameintdummycat);
      const sftvalueget = parseInt(details.formulaupdatedboxheightcat) * parseInt(details.formulaupdatedboxwidthcat);
      const sftqty = sftvalueget /90000;
      const SFT = sftqty * quantity ;
      const roundedSFT = SFT.toFixed(1);
      details.SFTValue = roundedSFT;
    } else if (
      (details.frametypecat == "1 x 1" || details.frametypecat == "1 x 1 Pa Frame") &&
      details.framedoortypecat == "Glass Door" &&
      details.framedoortypeselectcat == "Double"
    ) {
      height =
        parseInt(details.frameheightcat) - 22 - parseInt(boxint_framegcat);
      const widthCalculation =parseInt(details.framewidthcat) - 30 - parseInt(frameintdummycat);
      const widthsecstep = widthCalculation / 2;
      width = widthsecstep - 3;
      const sftvalueget = parseInt(details.formulaupdatedboxheightcat) * parseInt(details.formulaupdatedboxwidthcat);
      const sftqty = sftvalueget /90000;
      const SFT = sftqty * quantity ;
         const roundedSFT = SFT.toFixed(1);
         details.SFTValue = roundedSFT;
    } else if (
      (details.frametypecat === "1.25" ||
        details.frametypecat === "1.25 Pa Frame" ||
        details.frametypecat === "1.25 single lip") &&
      details.framedoortypecat === "Sheet Door" &&
      details.framedoortypeselectcat === "Single"
    ) {
      height =
        parseInt(details.frameheightcat) - 20 - parseInt(boxint_framegcat);
      width = parseInt(details.framewidthcat) - 20 - parseInt(frameintdummycat);
      
    } else if (
      (details.frametypecat == "1.25" ||
        details.frametypecat === "1.25 Pa Frame" ||
        details.frametypecat == "1.25 single lip") &&
      details.framedoortypecat == "Sheet Door" &&
      details.framedoortypeselectcat == "Double"
    ) {
      height =
        parseInt(details.frameheightcat) - 20 - parseInt(boxint_framegcat);
      const widthCalculation =
        parseInt(details.framewidthcat) - 42 - parseInt(frameintdummycat);
      width = widthCalculation / 2;
    } else if (
      (details.frametypecat == "1.25" ||
        details.frametypecat === "1.25 Pa Frame" ||
        details.frametypecat == "1.25 single lip")
         &&
      details.framedoortypecat == "Glass Door" &&
      details.framedoortypeselectcat == "Single"
    ) {
      height =
        parseInt(details.frameheightcat) - 22 - parseInt(boxint_framegcat);
      width = parseInt(details.framewidthcat) - 22 - parseInt(frameintdummycat);
      const sftvalueget = parseInt(details.formulaupdatedboxheightcat) * parseInt(details.formulaupdatedboxwidthcat);
      const sftqty = sftvalueget /90000;
      const SFT = sftqty * quantity ;
         const roundedSFT = SFT.toFixed(1);
         details.SFTValue = roundedSFT;
    } else if (
      (details.frametypecat == "1.25" ||
        details.frametypecat === "1.25 Pa Frame" ||
        details.frametypecat == "1.25 single lip") 
        &&
      details.framedoortypecat == "Glass Door" &&
      details.framedoortypeselectcat == "Double"
    ) {
      height =
        parseInt(details.frameheightcat) - 22 - parseInt(boxint_framegcat);
      const widthCalculation =
        parseInt(details.framewidthcat) - 47 - parseInt(frameintdummycat);
      width = widthCalculation / 2;
      const sftvalueget = parseInt(details.formulaupdatedboxheightcat) * parseInt(details.formulaupdatedboxwidthcat);
      const sftqty = sftvalueget /90000;
      const SFT = sftqty * quantity ;
         const roundedSFT = SFT.toFixed(1);
         details.SFTValue = roundedSFT;
    } else if (details.frametypecat == "Ep") {
      height = parseInt(details.frameheightcat);
      width = parseInt(details.framewidthcat);
    }
  }
  const heightquantity = height * quantity;
  const widthquantity = width * quantity;

  const updateheight = Math.floor(height);

  const updatedWidth =
    boxint_boxgcat > 0 || boxint_framegcat > 0
      ? Math.floor(width) + "G"
      : Math.floor(width);
      return {
    ...details,
    formulaupdatedboxheightcat: updateheight,
    formulaupdatedboxwidthcat: updatedWidth,
    NewSftValue:details.SFTValue,
    quantity,
    heightquantity,
    widthquantity,
  };
}
export function FormulaFaciaDrawer(details: any, category: any) {
  let height = details.boxheightcat ? parseInt(details.boxheightcat) : parseInt(details.frameheightcat);
  let width = details.boxwidthcat ? parseInt(details.boxwidthcat) : parseInt(details.framewidthcat);
  let quantity = details.frametypecat === "Ep" ? 1
      : details.boxdoor_selectcat === "3 drawer" ? 3
      : details.boxdoor_selectcat === "2 drawer" ? 2
      : details.boxdoor_selectcat === "3 drawer 2:1" ? 3
      : details.boxdoor_selectcat === "2 drawer 2:1" ? 2
      : details.boxdoor_selectcat === "Double" || details.framedoortypeselectcat === "Double" ? 2 : 1;
  let boxint_dummycat = details.boxint_dummycat;
  if (!boxint_dummycat) {
    boxint_dummycat = "0";
  }
  let frameintdummycat = details.frameintdummycat;
  if (!frameintdummycat) {
    frameintdummycat = "0";
  }
  let boxint_boxgcat = details.boxgcat;
  if (!boxint_boxgcat) {
    boxint_boxgcat = "0";
  }
  let boxint_framegcat = details.framegcat;
  if (!boxint_framegcat) {
    boxint_framegcat = "0";
  }

  if (details.boxdepthcat) {
    if (
      details.boxdoor_typecat === "Facia" &&
      details.FaciaDrawer == "1" &&
      details.boxdoor_selectcat === "2 drawer"
    ) {
      height = parseInt(details.boxheightcat) - 20 - parseInt(boxint_boxgcat);
      width = parseInt(details.boxwidthcat) - 78 - parseInt(boxint_dummycat);
    } 
     else if (
      details.boxdoor_typecat === "Facia" &&
      details.FaciaDrawer == "1" &&
      details.boxdoor_selectcat === "2 drawer 2:1"
    ) {
      height = parseInt(details.boxheightcat) - 20 - parseInt(boxint_boxgcat);
      width = parseInt(details.boxwidthcat) - 78 - parseInt(boxint_dummycat);
    } else if (
      details.boxdoor_typecat === "Facia" &&
      details.FaciaDrawer == "1" &&
      details.boxdoor_selectcat === "3 drawer"
    ) {
      height = parseInt(details.boxheightcat) - 20 - parseInt(boxint_boxgcat);
      width = parseInt(details.boxwidthcat) - 78 - parseInt(boxint_dummycat);
    }
    else if (
      details.boxdoor_typecat === "Facia" &&
      details.FaciaDrawer == "1" &&
      details.boxdoor_selectcat === "3 drawer 2:1"
    ) {
      height = parseInt(details.boxheightcat) - 20 - parseInt(boxint_boxgcat);
      width = parseInt(details.boxwidthcat) - 78 - parseInt(boxint_dummycat);
    } 
  } else {
    if (
      details.boxdoor_typecat === "Facia" &&
      details.FaciaDrawer == "1" &&
      details.boxdoor_selectcat === "2 drawer"
    ) {
      height = parseInt(details.boxheightcat) - 20 - parseInt(boxint_boxgcat);
      width = parseInt(details.boxwidthcat) - 78 - parseInt(boxint_dummycat);
    } 
     else if (
      details.boxdoor_typecat === "Facia" &&
      details.FaciaDrawer == "1" &&
      details.boxdoor_selectcat === "2 drawer 2:1"
    ) {
      height = parseInt(details.boxheightcat) - 20 - parseInt(boxint_boxgcat);
      width = parseInt(details.boxwidthcat) - 78 - parseInt(boxint_dummycat);
    } else if (
      details.boxdoor_typecat === "Facia" &&
      details.FaciaDrawer == "1" &&
      details.boxdoor_selectcat === "3 drawer"
    ) {
      height = parseInt(details.boxheightcat) - 20 - parseInt(boxint_boxgcat);
      width = parseInt(details.boxwidthcat) - 78 - parseInt(boxint_dummycat);
    }
    else if (
      details.boxdoor_typecat === "Facia" &&
      details.FaciaDrawer == "1" &&
      details.boxdoor_selectcat === "3 drawer 2:1"
    ) {
      height = parseInt(details.boxheightcat) - 20 - parseInt(boxint_boxgcat);
      width = parseInt(details.boxwidthcat) - 78 - parseInt(boxint_dummycat);
    } 
  }
  const heightquantity = height * quantity;
  const widthquantity = width * quantity;

  const updateheight = Math.floor(height);

  const updatedWidth =
    boxint_boxgcat > 0 || boxint_framegcat > 0
      ? Math.floor(width) + "G"
      : Math.floor(width);
  const depth = parseInt(details.boxdepthcat) - 50;
  return {
    ...details,
    formulaupdatedboxheightcat: updateheight,
    formulaupdatedboxwidthcat: updatedWidth,
    formulaupdatedboxdepthcat: depth,
    quantity,
    heightquantity,
    widthquantity,
  };
}
export function FormulaFaciaTendamDrawer(details: any, category: any,type:any) {
  let height = details.boxheightcat ? parseInt(details.boxheightcat) : parseInt(details.frameheightcat);
  let width = details.boxwidthcat ? parseInt(details.boxwidthcat) : parseInt(details.framewidthcat);
  let quantity = details.frametypecat === "Ep" ? 1
      : details.boxdoor_selectcat === "3 drawer" ? 3
      : details.boxdoor_selectcat === "2 drawer" ? 2
      : details.boxdoor_selectcat === "3 drawer 2:1" ? 3
      : details.boxdoor_selectcat === "2 drawer 2:1" ? 2
      : details.boxdoor_selectcat === "Double" || details.framedoortypeselectcat === "Double" ? 2 : 1;
  let boxint_dummycat = details.boxint_dummycat;
  if (!boxint_dummycat) {
    boxint_dummycat = "0";
  }
  let frameintdummycat = details.frameintdummycat;
  if (!frameintdummycat) {
    frameintdummycat = "0";
  }
  let boxint_boxgcat = details.boxgcat;
  if (!boxint_boxgcat) {
    boxint_boxgcat = "0";
  }
  let boxint_framegcat = details.framegcat;
  if (!boxint_framegcat) {
    boxint_framegcat = "0";
  }

  if (details.boxdepthcat) {
    if (
      details.boxdoor_typecat === "Facia" &&
      details.FaciaDrawer == "2" &&
      details.boxdoor_selectcat === "2 drawer"
    ) {
      if(type==="bottom panel"){
        height = parseInt(details.boxheightcat) - 20 - parseInt(boxint_boxgcat);
        width = parseInt(details.boxwidthcat) - 129 - parseInt(boxint_dummycat);
        const sftvalueget = parseInt(details.formulaupdatedboxheightcat) * parseInt(details.formulaupdatedboxwidthcat);
        const sftqty = sftvalueget /90000;
         const SFT = sftqty * quantity ;
         const roundedSFT = SFT.toFixed(1);
         details.SFTValue = roundedSFT;
      }else if(type==="back panel"){
        height =183;
        width = parseInt(details.boxwidthcat) - 139 - parseInt(boxint_dummycat);
        const sftvalueget = parseInt(details.formulaupdatedboxheightcat) * parseInt(details.formulaupdatedboxwidthcat);
        const sftqty = sftvalueget /90000;
         const SFT = sftqty * quantity ;
         const roundedSFT = SFT.toFixed(1);
         details.SFTValue = roundedSFT;
      }
    
    } 
     else if (
      details.boxdoor_typecat === "Facia" &&
      details.FaciaDrawer == "2" &&
      details.boxdoor_selectcat === "2 drawer 2:1"
    ) {
      if(type==="bottom panel"){
        height = parseInt(details.boxheightcat) - 20 - parseInt(boxint_boxgcat);
        width = parseInt(details.boxwidthcat) - 129 - parseInt(boxint_dummycat);
        const sftvalueget = parseInt(details.formulaupdatedboxheightcat) * parseInt(details.formulaupdatedboxwidthcat);
        const sftqty = sftvalueget /90000;
         const SFT = sftqty * quantity ;
         const roundedSFT = SFT.toFixed(1);
         details.SFTValue = roundedSFT;
      }else if(type==="back panel"){
        height =183;
        width = parseInt(details.boxwidthcat) - 139 - parseInt(boxint_dummycat);
        const sftvalueget = parseInt(details.formulaupdatedboxheightcat) * parseInt(details.formulaupdatedboxwidthcat);
        const sftqty = sftvalueget /90000;
         const SFT = sftqty * quantity ;
         const roundedSFT = SFT.toFixed(1);
         details.SFTValue = roundedSFT;
      }
    } else if (
      details.boxdoor_typecat === "Facia" &&
      details.FaciaDrawer == "2" &&
      details.boxdoor_selectcat === "3 drawer"
    ) {
      if(type==="bottom panel"){
        height = parseInt(details.boxheightcat) - 20 - parseInt(boxint_boxgcat);
        width = parseInt(details.boxwidthcat) - 129 - parseInt(boxint_dummycat);
        const sftvalueget = parseInt(details.formulaupdatedboxheightcat) * parseInt(details.formulaupdatedboxwidthcat);
        const sftqty = sftvalueget /90000;
         const SFT = sftqty * quantity ;
         const roundedSFT = SFT.toFixed(1);
         details.SFTValue = roundedSFT;
      }else if(type==="back panel"){
        height =183;
        width = parseInt(details.boxwidthcat) - 139 - parseInt(boxint_dummycat);
        const sftvalueget = parseInt(details.formulaupdatedboxheightcat) * parseInt(details.formulaupdatedboxwidthcat);
        const sftqty = sftvalueget /90000;
         const SFT = sftqty * quantity ;
         const roundedSFT = SFT.toFixed(1);
         details.SFTValue = roundedSFT;
      }
    }
    else if (
      details.boxdoor_typecat === "Facia" &&
      details.FaciaDrawer == "2" &&
      details.boxdoor_selectcat === "3 drawer 2:1"
    ) {
      if(type==="bottom panel"){
        height = parseInt(details.boxheightcat) - 20 - parseInt(boxint_boxgcat);
        width = parseInt(details.boxwidthcat) - 129 - parseInt(boxint_dummycat);
        const sftvalueget = parseInt(details.formulaupdatedboxheightcat) * parseInt(details.formulaupdatedboxwidthcat);
        const sftqty = sftvalueget /90000;
         const SFT = sftqty * quantity ;
         const roundedSFT = SFT.toFixed(1);
         details.SFTValue = roundedSFT;
      }else if(type==="back panel"){
        height =183;
        width = parseInt(details.boxwidthcat) - 139 - parseInt(boxint_dummycat);
        const sftvalueget = parseInt(details.formulaupdatedboxheightcat) * parseInt(details.formulaupdatedboxwidthcat);
        const sftqty = sftvalueget /90000;
         const SFT = sftqty * quantity ;
         const roundedSFT = SFT.toFixed(1);
         details.SFTValue = roundedSFT;
      }
    } 
  } else {
    
  }
  const heightquantity = height * quantity;
  const widthquantity = width * quantity;

  const updateheight = Math.floor(height);

  const updatedWidth =
    boxint_boxgcat > 0 || boxint_framegcat > 0
      ? Math.floor(width) 
      : Math.floor(width);
  const depth = 494;
   const result = {
        ...details,
        formulaupdatedboxheightcat: updateheight,
        formulaupdatedboxwidthcat: updatedWidth,
        formulaupdatedboxdepthcat: depth,
        TandemSftValue:details.SFTValue,
        quantity,
        heightquantity,
        widthquantity,
    };
    return result;
}

export function FormulaFaciaInternalDrawer(details: any, category: any,type:any) {
  let height = details.boxheightcat ? parseInt(details.boxheightcat) : parseInt(details.frameheightcat);
  let width = details.boxwidthcat ? parseInt(details.boxwidthcat) : parseInt(details.framewidthcat);
  let quantity = details.frametypecat === "Ep" ? 1
      : details.boxdoor_selectcat === "3 drawer" ? 3
      : details.boxdoor_selectcat === "2 drawer" ? 2
      : details.boxdoor_selectcat === "3 drawer 2:1" ? 3
      : details.boxdoor_selectcat === "2 drawer 2:1" ? 2
      : details.boxdoor_selectcat === "Single Drawer" ? 2
      : details.boxdoor_selectcat === "Double Drawer" ? 2
      : details.boxdoor_selectcat === "Double" || details.framedoortypeselectcat === "Double" ? 2 : 1;
  let boxint_dummycat = details.boxint_dummycat;
  if (!boxint_dummycat) {
    boxint_dummycat = "0";
  }
  let frameintdummycat = details.frameintdummycat;
  if (!frameintdummycat) {
    frameintdummycat = "0";
  }
  let boxint_boxgcat = details.boxgcat;
  if (!boxint_boxgcat) {
    boxint_boxgcat = "0";
  }
  let boxint_framegcat = details.framegcat;
  if (!boxint_framegcat) {
    boxint_framegcat = "0";
  }

  if (details.boxdepthcat) {
    if (
      details.boxdoor_typecat === "Facia" &&
      details.FaciaDrawer == "3" &&
      details.boxdoor_selectcat === "Single Drawer"
    ) {
      if(type==="Single lip ivory"){
        height = 250;
        width = parseInt(details.boxwidthcat) - 51;
      }else if(type==="100mm drawer ivory"){
        height =183;
        width = parseInt(details.boxwidthcat) - 179
      }else if(type==="Internal Facia steel"){
        height =230;
        width = parseInt(details.boxwidthcat) - 151 + 20;
      }
    } 
     else if (
      details.boxdoor_typecat === "Facia" &&
      details.FaciaDrawer == "3" &&
      details.boxdoor_selectcat === "Double Drawer"
    ) {
      if(type==="Single lip ivory"){
        height = 250;
        width = parseInt(details.boxwidthcat) - 51;
      }else if(type==="100mm drawer ivory"){
        height =183;
        const  forwidth = parseInt(details.boxwidthcat) - 201
       const fordividewidth = forwidth /2
        width = (fordividewidth) - 28;
      }else if(type==="Internal Facia steel"){
        height =230;
        const  forinwidth = parseInt(details.boxwidthcat) - 201
        const forindividewidth = forinwidth /2
         width = (forindividewidth) + 20;
      }
    }  
  } else {
    
  }
  const heightquantity = height * quantity;
  const widthquantity = width * quantity;

  const updateheight = Math.floor(height);

  const updatedWidth =
    boxint_boxgcat > 0 || boxint_framegcat > 0
      ? Math.floor(width) + "G"
      : Math.floor(width);
  const depth = 430;
   const result = {
        ...details,
        formulaupdatedboxheightcat: updateheight,
        formulaupdatedboxwidthcat: updatedWidth,
        formulaupdatedboxdepthcat: depth,
        quantity,
        heightquantity,
        widthquantity,
    };
    return result;
}


export function formuladoorsheet(details: any, category: any) {
  
  let height = details.boxheightcat ? parseInt(details.boxheightcat) : parseInt(details.frameheightcat);
  let width = details.boxwidthcat ? parseInt(details.boxwidthcat) : parseInt(details.framewidthcat);
  let quantity = details.frametypecat === "Ep" ? 1
      : details.boxdoor_selectcat === "3 drawer" ? 3
      : details.boxdoor_selectcat === "2 drawer" ? 2
      : details.boxdoor_selectcat === "3 drawer 2:1" ? 1
      : details.boxdoor_selectcat === "2 drawer 2:1" ? 1
      : details.boxdoor_selectcat === "Single Drawer" ? 2
      : details.boxdoor_selectcat === "Double Drawer" ? 2
      : details.boxdoor_selectcat === "Double" || details.framedoortypeselectcat === "Double" ? 2 : 1;
  let boxint_dummycat = details.boxint_dummycat;
  if (!boxint_dummycat) {
    boxint_dummycat = "0";
  }
  let frameintdummycat = details.frameintdummycat;
  if (!frameintdummycat) {
    frameintdummycat = "0";
  }
  let boxint_boxgcat = details.boxgcat;
  if (!boxint_boxgcat) {
    boxint_boxgcat = "0";
  }
  let boxint_framegcat = details.framegcat;
  if (!boxint_framegcat) {
    boxint_framegcat = "0";
  }
  if (details.boxdepthcat) {
    if (
      details.boxdoor_typecat === "Sheet Door" &&
      details.boxdoor_selectcat === "Single"
    ) {
      height = parseInt(details.boxheightcat) - 20 - parseInt(boxint_boxgcat);
      width = parseInt(details.boxwidthcat) - 20 - parseInt(boxint_dummycat);
    } else if (
      details.boxdoor_typecat == "Sheet Door" &&
      details.boxdoor_selectcat == "Double"
    ) {
      height = parseInt(details.boxheightcat) - 20 - parseInt(boxint_boxgcat);
      const widthCalculation =
        parseInt(details.boxwidthcat) - 28 - parseInt(boxint_dummycat);
      width = widthCalculation / 2;
    }
     else if (
      details.boxdoor_typecat == "Glass Door" &&
      details.boxdoor_selectcat == "Single"
    ) {
      height = parseInt(details.boxheightcat) - 22 - parseInt(boxint_boxgcat);
      width = parseInt(details.boxwidthcat) - 22 - parseInt(boxint_dummycat);
    } else if (
      details.boxdoor_typecat == "Glass Door" &&
      details.boxdoor_selectcat == "Double"
    ) {
      height = parseInt(details.boxheightcat) - 20 - parseInt(boxint_boxgcat);
      const widthCalculation =
      parseInt(details.boxwidthcat) - 28 - parseInt(boxint_dummycat);
      const widthsecstep = widthCalculation / 2  ;
      width = widthsecstep ;
    }
   
     else if (
      details.boxdoor_typecat == "Facia" &&
      details.boxdoor_selectcat == "2 drawer"
    ) {
      const heightCalculation =
        parseInt(details.boxheightcat) -
        24 -
        parseInt(boxint_boxgcat) -
        parseInt(boxint_boxgcat);
      height = heightCalculation / 2;
      width = parseInt(details.boxwidthcat) - 20 - parseInt(boxint_dummycat);
    } else if (
      details.boxdoor_typecat == "Facia" &&
      details.boxdoor_selectcat == "3 drawer"
    ) {
      const heightCalculation =
        parseInt(details.boxheightcat) -
        28 -
        parseInt(boxint_boxgcat) -
        parseInt(boxint_boxgcat);
      height = heightCalculation / 3;
      width = parseInt(details.boxwidthcat) - 20 - parseInt(boxint_dummycat);
    }
    //100mm  drawer 2 3 2:1//
    else if (
      details.boxdoor_typecat == "Facia" &&
      details.FaciaDrawer == "1" &&
      details.boxdoor_selectcat == "2 drawer 2:1" &&
      details.Twodrawer =="1" 
    ) {
      const heightCalculation =
        parseInt(details.boxheightcat) - 24 - parseInt(boxint_boxgcat) - parseInt(boxint_boxgcat);
      height = heightCalculation / 3;
      width = parseInt(details.boxwidthcat) - 20 - parseInt(boxint_dummycat);
    }  else if (
      details.boxdoor_typecat == "Facia" &&
      details.FaciaDrawer == "1" &&
      details.boxdoor_selectcat == "2 drawer 2:1" &&
      details.Twodrawer =="2" 
    ) {
      const heightCalculation = parseInt(details.boxheightcat) - 24 - parseInt(boxint_boxgcat) - parseInt(boxint_boxgcat);
     const  intoheight = heightCalculation / 3;
     height = intoheight * 2 ;
      width = parseInt(details.boxwidthcat) - 20 - parseInt(boxint_dummycat);
    } 
    else if (
      details.boxdoor_typecat == "Facia" &&
      details.FaciaDrawer == "1" &&
      details.boxdoor_selectcat == "3 drawer 2:1" &&
      details.Twodrawer =="1" 
    ) {
      const heightCalculation = parseInt(details.boxheightcat) - 28 -
      parseInt(boxint_boxgcat) -
      parseInt(boxint_boxgcat);
    const intoheight = heightCalculation / 2;
    height = intoheight /2 ;
    width = parseInt(details.boxwidthcat) - 20 - parseInt(boxint_dummycat);
    }
    else if (
      details.boxdoor_typecat == "Facia" &&
      details.FaciaDrawer == "1" &&
      details.boxdoor_selectcat == "3 drawer 2:1" &&
      details.Twodrawer =="2" 
    ) {
      const heightCalculation = parseInt(details.boxheightcat) - 28 -
      parseInt(boxint_boxgcat) -
      parseInt(boxint_boxgcat);
    const intoheight = heightCalculation / 2;
    height = intoheight /2 ;
    width = parseInt(details.boxwidthcat) - 20 - parseInt(boxint_dummycat);
    }
    else if (
      details.boxdoor_typecat == "Facia" &&
      details.FaciaDrawer == "1" &&
      details.boxdoor_selectcat == "3 drawer 2:1" &&
      details.Twodrawer =="3" 
    ) {
      const heightCalculation = parseInt(details.boxheightcat) - 28 -
      parseInt(boxint_boxgcat) -
      parseInt(boxint_boxgcat);
    height = heightCalculation /2 ;
    width = parseInt(details.boxwidthcat) - 20 - parseInt(boxint_dummycat);
    }
    //tendem trawer 2 3 2:1//
    else if (
      details.boxdoor_typecat == "Facia" &&
      details.FaciaDrawer == "2" &&
      details.boxdoor_selectcat == "2 drawer 2:1" &&
      details.Twodrawer =="1" 
    ) {
      const heightCalculation =
        parseInt(details.boxheightcat) - 24 - parseInt(boxint_boxgcat) - parseInt(boxint_boxgcat);
      height = heightCalculation / 3;
      width = parseInt(details.boxwidthcat) - 20 - parseInt(boxint_dummycat);
    }  else if (
      details.boxdoor_typecat == "Facia" &&
      details.FaciaDrawer == "2" &&
      details.boxdoor_selectcat == "2 drawer 2:1" &&
      details.Twodrawer =="2" 
    ) {
      const heightCalculation = parseInt(details.boxheightcat) - 24 - parseInt(boxint_boxgcat) - parseInt(boxint_boxgcat);
     const  intoheight = heightCalculation / 3;
     height = intoheight * 2 ;
      width = parseInt(details.boxwidthcat) - 20 - parseInt(boxint_dummycat);
    } 
    else if (
      details.boxdoor_typecat == "Facia" &&
      details.FaciaDrawer == "2" &&
      details.boxdoor_selectcat == "3 drawer 2:1" &&
      details.Twodrawer =="1" 
    ) {
      const heightCalculation = parseInt(details.boxheightcat) - 28 -
      parseInt(boxint_boxgcat) -
      parseInt(boxint_boxgcat);
    const intoheight = heightCalculation / 2;
    height = intoheight /2 ;
    width = parseInt(details.boxwidthcat) - 20 - parseInt(boxint_dummycat);
    }
    else if (
      details.boxdoor_typecat == "Facia" &&
      details.FaciaDrawer == "2" &&
      details.boxdoor_selectcat == "3 drawer 2:1" &&
      details.Twodrawer =="2" 
    ) {
      const heightCalculation = parseInt(details.boxheightcat) - 28 -
      parseInt(boxint_boxgcat) -
      parseInt(boxint_boxgcat);
    const intoheight = heightCalculation / 2;
    height = intoheight /2 ;
    width = parseInt(details.boxwidthcat) - 20 - parseInt(boxint_dummycat);
    }
    else if (
      details.boxdoor_typecat == "Facia" &&
      details.FaciaDrawer == "2" &&
      details.boxdoor_selectcat == "3 drawer 2:1" &&
      details.Twodrawer =="3" 
    ) {
      const heightCalculation = parseInt(details.boxheightcat) - 28 -
      parseInt(boxint_boxgcat) -
      parseInt(boxint_boxgcat);
    height = heightCalculation /2 ;
    width = parseInt(details.boxwidthcat) - 20 - parseInt(boxint_dummycat);
    }
    else if (
      details.boxdoor_typecat == "Facia" &&
      details.FaciaDrawer == "3" &&
      details.boxdoor_selectcat == "Single Drawer" 
    ) {
      height = parseInt(details.boxheightcat) - 20 - parseInt(boxint_boxgcat);
      width = parseInt(details.boxwidthcat) - 20 - parseInt(boxint_dummycat);
    }
    else if (
      details.boxdoor_typecat == "Facia" &&
      details.FaciaDrawer == "3" &&
      details.boxdoor_selectcat == "Double Drawer" 
    ) {
      height = parseInt(details.boxheightcat) - 20 - parseInt(boxint_boxgcat);
      const widthCalculation =
        parseInt(details.boxwidthcat) - 30 - parseInt(boxint_dummycat);
      width = widthCalculation / 2;
    }
    else if (
      details.boxdoor_typecat == "Facia" &&
      details.FaciaDrawer == "0" && details.boxg_valuecat =="1"
    ) {
      height = parseInt(details.boxheightcat) - 20 - parseInt(details.boxgcat) ;
      width = parseInt(details.boxwidthcat) - 20  ;
    }
    else if (
      details.boxdoor_typecat == "Facia" &&
      details.FaciaDrawer == "0" 
    ) {
      height = parseInt(details.boxheightcat) - 20 ;
      width = parseInt(details.boxwidthcat) - 20 ;
    }
  }
   else {
    if (
      (details.frametypecat == "1 x 1" || details.frametypecat == "1 x 1 Pa Frame") &&
      details.framedoortypecat == "Sheet Door" &&
      details.framedoortypeselectcat ===   "Single"
    ) {
      height =
        parseInt(details.frameheightcat) - 20 - parseInt(boxint_framegcat);
      width = parseInt(details.framewidthcat) - 20 - parseInt(frameintdummycat);
    } else if (
      (details.frametypecat == "1 x 1" || details.frametypecat == "1 x 1 Pa Frame") &&
      details.framedoortypecat == "Sheet Door" &&
      details.framedoortypeselectcat === "Double"
    ) {
      height =
        parseInt(details.frameheightcat) - 20 - parseInt(boxint_framegcat);
      const widthCalculation =
        parseInt(details.framewidthcat) - 28 - parseInt(frameintdummycat);
      width = widthCalculation / 2;
    } else if (
      (details.frametypecat == "1 x 1" || details.frametypecat == "1 x 1 Pa Frame") &&
      details.framedoortypecat == "Glass Door" &&
      details.framedoortypeselectcat == "Single"
    ) {
      height =
        parseInt(details.frameheightcat) - 22 - parseInt(boxint_framegcat);
      width = parseInt(details.framewidthcat) - 22 - parseInt(frameintdummycat);
    } else if (
      (details.frametypecat == "1 x 1" || details.frametypecat == "1 x 1 Pa Frame") &&
      details.framedoortypecat == "Glass Door" &&
      details.framedoortypeselectcat == "Double"
    ) {
      height =
        parseInt(details.frameheightcat) - 22 - parseInt(boxint_framegcat);
      const widthCalculation =
        parseInt(details.framewidthcat) - 28 - parseInt(frameintdummycat);
      width = widthCalculation / 2;
    } else if (
      (details.frametypecat === "1.25" ||
        details.frametypecat === "1.25 Pa Frame" ||
        details.frametypecat === "1.25 single lip") &&
      details.framedoortypecat === "Sheet Door" &&
      details.framedoortypeselectcat === "Single"
    ) {
      height =
        parseInt(details.frameheightcat) - 20 - parseInt(boxint_framegcat);
      width = parseInt(details.framewidthcat) - 20 - parseInt(frameintdummycat);
    } else if (
      (details.frametypecat == "1.25" ||
        details.frametypecat === "1.25 Pa Frame" ||
        details.frametypecat == "1.25 single lip") &&
      details.framedoortypecat == "Sheet Door" &&
      details.framedoortypeselectcat == "Double"
    ) {
      height =
        parseInt(details.frameheightcat) - 20 - parseInt(boxint_framegcat);
      const widthCalculation =
        parseInt(details.framewidthcat) - 32 - parseInt(frameintdummycat);
      width = widthCalculation / 2;
    } else if (
      (details.frametypecat == "1.25" ||
        details.frametypecat === "1.25 Pa Frame" ||
        details.frametypecat == "1.25 single lip") &&
      details.framedoortypecat == "Glass Door" &&
      details.framedoortypeselectcat == "Single"
    ) {
      height =
        parseInt(details.frameheightcat) - 22 - parseInt(boxint_framegcat);
      width = parseInt(details.framewidthcat) - 22 - parseInt(frameintdummycat);
    } else if (
      (details.frametypecat == "1.25" ||
        details.frametypecat === "1.25 Pa Frame" ||
        details.frametypecat == "1.25 single lip") &&
      details.framedoortypecat == "Glass Door" &&
      details.framedoortypeselectcat == "Double"
    ) {
      height =
        parseInt(details.frameheightcat) - 22 - parseInt(boxint_framegcat);
      const widthCalculation =
        parseInt(details.framewidthcat) - 47 - parseInt(frameintdummycat);
      width = widthCalculation / 2;
    } else if (details.frametypecat == "Ep") {
      height = parseInt(details.frameheightcat);
      width = parseInt(details.framewidthcat);
    }
  }
  const heightquantity = height * quantity;
  const widthquantity = width * quantity;

  const updateheight = Math.floor(height);

  const updatedWidth =
    boxint_boxgcat > 0 || boxint_framegcat > 0
      ? Math.floor(width) 
      : Math.floor(width);

  return {
    ...details,
    formulaupdatedboxheightcat: updateheight,
    formulaupdatedboxwidthcat: updatedWidth,
    quantity,
    heightquantity,
    widthquantity,
  };
}

export function formulaIvorySheet(details: any, category: any) {
  let height = details.boxheightcat ? parseInt(details.boxheightcat) : parseInt(details.frameheightcat);
  let width = details.boxwidthcat ? parseInt(details.boxwidthcat) : parseInt(details.framewidthcat);
  let quantity = details.frametypecat === "Ep" ? 1
      : details.boxdoor_selectcat === "3 drawer" ? 3
      : details.boxdoor_selectcat === "2 drawer" ? 2
      : details.boxdoor_selectcat === "3 drawer 2:1" ? 1
      : details.boxdoor_selectcat === "2 drawer 2:1" ? 1
      : details.boxdoor_selectcat === "Single Drawer" ? 2
      : details.boxdoor_selectcat === "Double Drawer" ? 2
      : details.boxdoor_selectcat === "Double" || details.framedoortypeselectcat === "Double" ? 2 : 1;
  let boxint_dummycat = details.boxint_dummycat;
  if (!boxint_dummycat) {
    boxint_dummycat = "0";
  }
  let frameintdummycat = details.frameintdummycat;
  if (!frameintdummycat) {
    frameintdummycat = "0";
  }
  let boxint_boxgcat = details.boxgcat;
  if (!boxint_boxgcat) {
    boxint_boxgcat = "0";
  }
  let boxint_framegcat = details.framegcat;
  if (!boxint_framegcat) {
    boxint_framegcat = "0";
  }

  if (details.boxdepthcat) {
    if (
      details.boxdoor_typecat === "Sheet Door" &&
      details.boxdoor_selectcat === "Single"
    ) {
      height = parseInt(details.boxheightcat) - 20 - parseInt(boxint_boxgcat);
      width = parseInt(details.boxwidthcat) - 20 - parseInt(boxint_dummycat);
    } else if (
      details.boxdoor_typecat == "Sheet Door" &&
      details.boxdoor_selectcat == "Double"
    ) {
      height = parseInt(details.boxheightcat) - 20 - parseInt(boxint_boxgcat);
      const widthCalculation =
        parseInt(details.boxwidthcat) - 30 - parseInt(boxint_dummycat);
      width = widthCalculation / 2;
    }
    //100mm  drawer 2 3 2:1//
   
  } else {
    if (
      (details.frametypecat == "1 x 1" || details.frametypecat == "1 x 1 Pa Frame") &&
      details.framedoortypecat == "Sheet Door" &&
      details.framedoortypeselectcat ===   "Single"
    ) {
      height =
        parseInt(details.frameheightcat) - 20 - parseInt(boxint_framegcat);
      width = parseInt(details.framewidthcat) - 20 - parseInt(frameintdummycat);
    } else if (
      (details.frametypecat == "1 x 1" || details.frametypecat == "1 x 1 Pa Frame") &&
      details.framedoortypecat == "Sheet Door" &&
      details.framedoortypeselectcat === "Double"
    ) {
      height =
        parseInt(details.frameheightcat) - 20 - parseInt(boxint_framegcat);
      const widthCalculation =
        parseInt(details.framewidthcat) - 30 - parseInt(frameintdummycat);
      width = widthCalculation / 2;
    } 
  }
  const heightquantity = height * quantity;
  const widthquantity = width * quantity;

  const updateheight = Math.floor(height);

  const updatedWidth =
    boxint_boxgcat > 0 || boxint_framegcat > 0
      ? Math.floor(width) + "G"
      : Math.floor(width);
  const depth = 430;
   const result = {
        ...details,
        formulaupdatedboxheightcat: updateheight,
        formulaupdatedboxwidthcat: updatedWidth,
        formulaupdatedboxdepthcat: depth,
        quantity,
        heightquantity,
        widthquantity,
    };
    return result;
}
